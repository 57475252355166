<template>
    <Dialog :visible="visible" data-qs="create-qr-code-dialog" class="create-qr-code-dialog">
        <template #header>
            <div class="row items-center">
                <QitButton
                    class="back-btn"
                    color="secondary"
                    size="sm"
                    icon="fa-regular fa-arrow-left icon-color"
                    @click="onClose"
                />
                <div class="q-pl-md bold text-h6">
                    {{ $t("core.Connect device") }}
                </div>
            </div>
        </template>
        <template #content>
            <div class="q-pb-lg">
                {{
                    $t(
                        "core.With the help of the InfoTwin Companion App you can download content from InfoTwin to mobile devices"
                    )
                }}
                <br />
                {{ $t("core.To do this you need to connect your InfoTwin profile to the Companion App") }}
                <br />
                <br />
                {{
                    $t(
                        "core.Create a key with a QR code for the connection Existing keys become obsolete as soon as you create one"
                    )
                }}
            </div>
            <div v-if="loading">
                <div class="row justify-center">
                    <q-spinner-ios size="2em" />
                </div>
            </div>
            <div v-else-if="state === 'initial'">
                <QitButton
                    data-qs="connect-device-btn"
                    class="full-width"
                    color="primary"
                    :label="$t('core.Create connection')"
                    @click="setCreateState"
                />
            </div>
            <div v-else-if="state === 'reset'">
                <QitButton
                    data-qs="reset-link-btn"
                    class="full-width border"
                    color="white"
                    :label="$t('core.Reset connection')"
                    @click="openResetDialogue"
                />
            </div>
            <CreateQrCode v-else-if="state === 'create'" @on-reset="openResetDialogue" />
            <ResetDeviceLinkDialog
                :visible="isResetLinkDialogVisible"
                @on-close="closeResetDialogue"
                @on-reset-link="resetLink"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, toRefs, watchEffect } from "vue";
import Dialog from "@/shared/components/dialog/dialog.vue";
import CreateQrCode from "@/shared/components/connect-device/create-qr-code.vue";
import { useAppKeyLazyQuery } from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { watchImmediate } from "@vueuse/core";
import QitButton from "@/shared/components/buttons/qit-button.vue";
import ResetDeviceLinkDialog from "@/shared/components/connect-device/reset-device-link-dialog.vue";

const props = defineProps<{ visible: boolean }>();

const emit = defineEmits<{
    onClose: [];
}>();

const { visible } = toRefs(props);
const state = ref<"initial" | "create" | "reset">("initial");
const isResetLinkDialogVisible = ref<boolean>(false);

const { result, loading, load } = useAppKeyLazyQuery();

watchImmediate(
    () => props.visible,
    async () => {
        if (!props.visible) return;

        await load();
    }
);

watchEffect(() => {
    state.value = result.value?.appKey ? "reset" : "initial";
});

const onClose = () => {
    emit("onClose");

    if (state.value === "initial") return;

    state.value = result.value?.appKey ? "reset" : "create";
};

const setCreateState = () => {
    state.value = "create";
};

const resetLink = () => {
    isResetLinkDialogVisible.value = false;

    state.value = "create";
};

const openResetDialogue = () => {
    isResetLinkDialogVisible.value = true;
};

const closeResetDialogue = () => {
    isResetLinkDialogVisible.value = false;
    state.value = result.value?.appKey ? "reset" : "create";
};
</script>

<style lang="scss" scoped>
.back-btn {
    width: 36px;
    height: 36px;
}
</style>
<style lang="scss">
.create-qr-code-dialog {
    :deep(.dialog-card) {
        max-width: 450px !important;
    }
}
</style>
