<template>
    <div v-if="loading" class="loading row justify-center">
        <q-spinner-ios size="2em" />
    </div>
    <template v-else-if="appKey">
        <div class="semi-bold row no-wrap">
            <div>1.&nbsp;</div>
            <div>
                {{ $t("core.Open the Companion App on the required mobile devices") }}
            </div>
        </div>
        <div class="semi-bold row no-wrap q-mt-md">
            <div>2.&nbsp;</div>
            <div>{{ $t("core.Scan the QR code or use the key") }}</div>
        </div>
        <div class="column justify-center items-center">
            <div class="q-pt-md">
                <vue-qrcode :value="appKey.apiPass" :options="{ width: 250, errorCorrectionLevel: 'L' }" />
            </div>
            <div class="light-text-color">
                {{
                    `${$t("core.QR-Code valid until")} ${new Intl.DateTimeFormat(locale).format(
                        new Date(appKey.valid)
                    )}`
                }}
            </div>
            <qit-button
                data-qs="copy-key-btn"
                class="full-width q-mt-md"
                color="secondary"
                :label="$t('core.Copy key')"
                icon="fa-regular fa-copy icon-color"
                @click="copyKey"
            />
            <qit-button
                data-qs="reset-link-btn"
                class="full-width q-mt-md border"
                color="white"
                :label="$t('core.Reset connection')"
                @click="$emit('onReset')"
            />
        </div>
    </template>
</template>

<script setup lang="ts">
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { computed, onMounted, ref } from "vue";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";
import { useCreateAppKeyMutation } from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { useClipboard } from "@vueuse/core";
import { getUiLanguage } from "@/shared/services/providers/language-provider.ts";
import QitButton from "@/shared/components/buttons/qit-button.vue";

defineEmits<{ onReset: [] }>();

const loading = ref<boolean>(true);
const appKey = ref<undefined | { apiPass: string; valid: string }>();

const { notify } = useQuasar();
const { t } = useI18n();
const { mutate } = useCreateAppKeyMutation();
const { copy } = useClipboard();

onMounted(async () => {
    await createCode();
});

const locale = computed(() => {
    return getUiLanguage();
});

const createCode = async () => {
    loading.value = true;

    try {
        const result: any = await mutate();
        if (result?.data?.createAppKey)
            appKey.value = {
                apiPass: result.data.createAppKey.apiPass,
                valid: result.data.createAppKey.key.validUntil,
            };
    } catch (e: any) {
        console.error(e);
        notify({ message: typeof e === "string" ? e : e.message, type: "negative" });
    } finally {
        loading.value = false;
    }
};

const copyKey = () => {
    if (!appKey.value) return;

    copy(appKey.value.apiPass);
    notify({ message: t("core.The key has been copied to the clipboard"), type: "positive" });
};
</script>

<style lang="scss" scoped>
.loading {
    height: 50px;
}
</style>
