<template>
    <Dialog
        :visible="isModalVisible"
        :title="$t('core.Date')"
        @on-close="() => $emit('onClose')"
        data-qs="date-picker-dialog"
        class="date-picker-dialog"
    >
        <template #content>
            <div class="flex">
                <div class="flex column q-mr-lg time-periods">
                    <QitButton
                        flat
                        color="primary"
                        text-color="on-link-color"
                        class="items-start"
                        data-qs="select-today-btn"
                        :class="{ active: enabledTimePeriod === 'today' }"
                        :label="$t('core.Today')"
                        :disabled="dateMode === 'dateRange'"
                        @click="() => selectTimePeriod('today')"
                    />
                    <QitButton
                        flat
                        class="items-start"
                        color="primary"
                        text-color="on-link-color"
                        data-qs="select-last-7-days-btn"
                        :class="{ active: enabledTimePeriod === 'last7Days' }"
                        :label="$t('core.Last 7 days')"
                        :disabled="dateMode !== 'dateRange'"
                        @click="() => selectTimePeriod('last7Days')"
                    />
                    <QitButton
                        flat
                        color="primary"
                        text-color="on-link-color"
                        class="items-start"
                        data-qs="select-last-30-days-btn"
                        :class="{ active: enabledTimePeriod === 'last30Days' }"
                        :label="$t('core.Last 30 days')"
                        :disabled="dateMode !== 'dateRange'"
                        @click="() => selectTimePeriod('last30Days')"
                    />
                    <QitButton
                        flat
                        color="primary"
                        text-color="on-link-color"
                        class="items-start"
                        data-qs="select-current-month-btn"
                        :class="{ active: enabledTimePeriod === 'currentMonth' }"
                        :label="$t('core.Current month')"
                        :disabled="dateMode !== 'dateRange'"
                        @click="() => selectTimePeriod('currentMonth')"
                    />
                    <QitButton
                        flat
                        color="primary"
                        text-color="on-link-color"
                        class="items-start"
                        data-qs="select-last-month-btn"
                        :class="{ active: enabledTimePeriod === 'lastMonth' }"
                        :label="$t('core.Last month')"
                        :disabled="dateMode !== 'dateRange'"
                        @click="() => selectTimePeriod('lastMonth')"
                    />
                    <QitButton
                        flat
                        color="primary"
                        text-color="on-link-color"
                        class="items-start"
                        data-qs="select-current-year-btn"
                        :class="{ active: enabledTimePeriod === 'currentYear' }"
                        :label="$t('core.Current year')"
                        :disabled="dateMode !== 'dateRange'"
                        @click="() => selectTimePeriod('currentYear')"
                    />
                </div>
                <div>
                    <div class="q-mb-md">
                        <q-btn-group unelevated class="btn-group">
                            <QitButton
                                data-qs="select-date-range-mode-btn"
                                :color="dateMode === 'dateRange' ? 'primary' : 'white'"
                                :label="$t('core.Time period')"
                                @click="() => onDateModeChange('dateRange')"
                            />
                            <QitButton
                                data-qs="select-date-mode-btn"
                                :color="dateMode === 'date' ? 'primary' : 'white'"
                                :label="$t('core.Date')"
                                @click="() => onDateModeChange('date')"
                            />
                        </q-btn-group>
                    </div>
                    <div class="flex gap">
                        <div class="q-mb-md">
                            <div class="input-label">
                                {{ dateMode === "dateRange" ? $t("core.From") : $t("core.Date") }}
                            </div>
                            <q-input
                                data-qs="date-from-input"
                                ref="dateFromRef"
                                v-model="dateFromString"
                                @update:model-value="onInputDateFrom"
                                @keyup="keyUpInputHandler"
                                :mask="maskInput"
                                :error="dateFromError"
                                :placeholder="$t('core.Start (optional)')"
                                :hint="hint"
                                :error-message="format"
                                tabindex="1"
                                dense
                                outlined
                                clearable
                            />
                            <q-date
                                data-qs="date-from-calendar"
                                v-model="dateModelFrom"
                                @update:model-value="updateDateFrom"
                                :mask="format"
                                class="q-pa-none q-mt-lg"
                                minimal
                                flat
                            />
                        </div>
                        <template v-if="dateMode === 'dateRange'">
                            <div class="items-center q-mt-lg">-</div>
                            <div class="q-mb-md">
                                <div class="input-label">{{ $t("core.To") }}</div>
                                <q-input
                                    data-qs="date-to-input"
                                    ref="dateToRef"
                                    v-model="dateToString"
                                    @update:model-value="onInputDateTo"
                                    @keyup="keyUpInputHandler"
                                    :mask="maskInput"
                                    :error="dateToError"
                                    :placeholder="$t('core.End (optional)')"
                                    :hint="hint"
                                    :error-message="format"
                                    tabindex="2"
                                    dense
                                    outlined
                                    clearable
                                />
                                <q-date
                                    data-qs="date-to-calendar"
                                    v-model="dateModelTo"
                                    @update:model-value="updateDateTo"
                                    :mask="format"
                                    class="q-pa-none q-mt-lg"
                                    minimal
                                    flat
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <QitButton
                flat
                data-qs="reset-btn"
                icon="fa-solid fa-arrows-rotate"
                color="primary"
                :label="$t('core.Reset')"
                @click="onReset"
            />
            <QitButton
                data-qs="apply-btn"
                color="primary"
                :label="$t('core.Apply')"
                :disable="!isFormValid"
                @click="onApply"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, useTemplateRef, watchEffect } from "vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";
import { QInput } from "quasar";
import { convertTimePeriod, convertToUTC, DateRange, dayjs, TimePeriod } from "@/shared/utils/date-utils.ts";
import { DateMode, DatePickerSelection } from "@/shared/components/date-picker/date-picker.types.ts";
import Dialog from "@/shared/components/dialog/dialog.vue";

const props = defineProps<{
    format: string;
    hint?: string;
    mode?: DateMode;
    date?: string | DateRange;
}>();

const emit = defineEmits<{
    onClose: [];
    onReset: [];
    onSelectDate: [selection: DatePickerSelection];
}>();

const dateFromRef = useTemplateRef<QInput>("dateFromRef");
const dateToRef = useTemplateRef<QInput>("dateToRef");
const isFormValid = ref<boolean>(false);
const isModalVisible = ref<boolean>(true);
const dateMode = ref<DateMode>("dateRange");
const dateFromString = ref<string>("");
const dateToString = ref<string>("");
const enabledTimePeriod = ref<TimePeriod | null>(null);
const dateModelTo = ref<string>("");
const dateModelFrom = ref<string>("");

onMounted(() => {
    if (!props.mode) return;

    dateMode.value = props.mode;

    if (!props.date) return;

    if (props.mode === "date") {
        const isValid = typeof props.date === "string" && props.date;

        dateFromString.value = isValid ? props.date : "";
        dateModelTo.value = isValid ? props.date : "";
    } else if (props.mode === "dateRange") {
        const predefinedDateRange = props.date as DateRange;
        const isValid = predefinedDateRange.from || predefinedDateRange.to;

        dateFromString.value = isValid ? predefinedDateRange.from : "";
        dateModelFrom.value = isValid ? predefinedDateRange.from : "";
        dateToString.value = isValid ? predefinedDateRange.to : "";
        dateModelTo.value = isValid ? predefinedDateRange.to : "";
    }
});

const maskInput = computed(() => {
    return props.format.replace(/[a-zA-Z]/g, "#");
});

const fromDate = computed(() => {
    return dayjs(dateFromString.value, props.format, true);
});

const toDate = computed(() => {
    return dayjs(dateToString.value, props.format, true);
});

const dateToIsEmpty = computed(() => {
    return dateToString.value.length === 0;
});

const dateFromIsEmpty = computed(() => {
    return dateFromString.value.length === 0;
});

const dateFromError = computed(() => {
    if ((dateFromIsEmpty.value && dateToIsEmpty.value) || (dateFromIsEmpty.value && !dateToIsEmpty.value)) {
        return false;
    }

    return !fromDate.value.isValid();
});

const dateToError = computed(() => {
    if ((dateFromIsEmpty.value && dateToIsEmpty.value) || (dateToIsEmpty.value && !dateFromIsEmpty.value)) {
        return false;
    }

    if (dateMode.value !== "dateRange") return true;

    if (!toDate.value.isValid()) return true;

    return toDate.value.isSameOrBefore(fromDate.value);
});

watchEffect(async () => {
    let isValid = false;

    if (dateMode.value === "dateRange") {
        if (!dateToIsEmpty.value && !dateFromIsEmpty.value) {
            //date range
            isValid = toDate.value.isValid() && fromDate.value.isValid() && fromDate.value.isSameOrBefore(toDate.value);
        } else if (!dateFromIsEmpty.value && dateToIsEmpty.value) {
            //date from with open start
            isValid = fromDate.value.isValid();
        } else if (!dateToIsEmpty.value && dateFromIsEmpty.value) {
            //date to with open end
            isValid = toDate.value.isValid();
        }
    } else if (dateMode.value === "date") {
        isValid = fromDate.value.isValid();
    }

    isFormValid.value = isValid;
});

const selectTimePeriod = (timePeriod: TimePeriod) => {
    enabledTimePeriod.value = timePeriod;
    let time = convertTimePeriod(timePeriod, props.format);
    if (!time) return;

    if (timePeriod === "today") {
        dateMode.value = "date";
        dateFromString.value = time.from;
        dateModelTo.value = time.to;
        dateModelFrom.value = time.from;
        return;
    }

    dateFromString.value = time.from;
    dateToString.value = time.to;
    dateModelTo.value = time.to;
    dateModelFrom.value = time.from;
};

const onReset = async () => {
    dateFromString.value = "";
    dateToString.value = "";
    dateModelTo.value = "";
    dateModelFrom.value = "";
    enabledTimePeriod.value = null;

    emit("onReset");
};

const onDateModeChange = (newDateMode: "date" | "dateRange") => {
    dateMode.value = newDateMode;
    enabledTimePeriod.value = null;
};

const updateDateFrom = (dateFrom: string) => {
    dateFromString.value = dateFrom;
    dateModelFrom.value = dateFrom;
    enabledTimePeriod.value = null;
};

const updateDateTo = (dateTo: string) => {
    dateToString.value = dateTo;
    dateModelTo.value = dateTo;
    enabledTimePeriod.value = null;
};

const onInputDateFrom = (value: string | number | null) => {
    dateFromString.value = value as string;

    if (value === null || (typeof value === "string" && fromDate.value.isValid())) updateDateFrom(value ?? "");
};

const onInputDateTo = (value: string | number | null) => {
    dateToString.value = value as string;
    const inputDateTo = dayjs(value, props.format, true);

    if (
        value === null ||
        (typeof value === "string" && inputDateTo.isValid() && inputDateTo.isSameOrAfter(fromDate.value))
    ) {
        updateDateTo(value ?? "");
    }
};

const keyUpInputHandler = async (event: KeyboardEvent) => {
    if (event.key !== "Enter") return;

    if (isFormValid.value) onApply();
};

const onApply = () => {
    if (dateMode.value === "date") {
        emit("onSelectDate", convertToUTC(dateFromString.value, props.format));
    } else if (dateMode.value === "dateRange") {
        const dateFrom = dateFromString.value ? convertToUTC(dateFromString.value, props.format) : undefined;
        const dateTo = dateToString.value ? convertToUTC(dateToString.value, props.format) : undefined;

        emit("onSelectDate", { from: dateFrom, to: dateTo });
    }
};
</script>

<style lang="scss" scoped>
:deep(.q-date__view) {
    padding: 0;
}
.q-btn.active {
    background: var(--q-highlight);
}
.btn-group {
    border-radius: $default-border-radius;
    border: 1px solid $default-border-color;
    &:deep(.q-btn.bg-white:not([disabled]):hover) {
        border-color: $default-border-color !important;
        background: $light-background-color !important;
    }
}
.time-periods {
    &:deep(.q-btn:not(.q-btn-dropdown):disabled) {
        background: transparent !important;
    }
}
.date-container {
    min-height: 39px;
}
</style>

<style lang="scss">
.date-picker-dialog {
    .dialog-card {
        .screen--xl &,
        .screen--lg & {
            max-width: 80vw !important;
        }
    }
    .q-card__section {
        overflow: auto;
    }
}
</style>
