import { DatePickerSelection } from "@/shared/components/date-picker/date-picker.types.ts";

import _dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";

_dayjs.extend(customParseFormat);
_dayjs.extend(isSameOrAfter);
_dayjs.extend(isSameOrBefore);
_dayjs.extend(utc);

export const dayjs = _dayjs;
export type TimePeriod = "today" | "last7Days" | "last30Days" | "currentMonth" | "lastMonth" | "currentYear";

export type DateRange = {
    from: string;
    to: string;
};

export const URL_PARAM_DATE_FORMAT = "YYYY-MM-DD";
//Less than or equal to
export const LTE_CHAR = "<";
//Greater than or equal to
export const GTE_CHAR = ">";
//Between two dates
export const BTW_CHAR = "_";
export const VALID_DATE_LENGTH = 10;

export const convertTimePeriod = (timePeriod: TimePeriod, dateFormat: string): DateRange | null => {
    const now = dayjs();
    let from: Dayjs;
    let to: Dayjs = now;

    switch (timePeriod) {
        case "today":
            from = now;
            break;
        case "last7Days":
            from = dayjs().subtract(7, "days");
            break;
        case "last30Days":
            from = dayjs().subtract(30, "days");
            break;
        case "currentMonth":
            from = dayjs().startOf("month");
            break;
        case "lastMonth":
            from = dayjs().subtract(1, "month").startOf("month");
            to = dayjs().subtract(1, "month").endOf("month");
            break;
        case "currentYear":
            from = dayjs().startOf("year");
            break;
        default:
            console.error(`Invalid time period: ${timePeriod}`);
            return null;
    }

    return {
        from: from.format(dateFormat),
        to: to.format(dateFormat),
    };
};

export const getDateFormat = (locale: string): string => {
    switch (locale) {
        case "de":
            return "DD.MM.YYYY";
        default:
            return "DD/MM/YYYY";
    }
};

export const getShortDateFormat = (locale: string): string => {
    switch (locale) {
        case "de":
            return "DD.MM.YY";
        default:
            return "DD/MM/YY";
    }
};

export const getLocalizedDateFormat = (lang: string, format: "short" | "long" = "long") => {
    let dateFormat = lang === "de" ? "TT.MM." : "DD/MM/";

    if (lang === "de") dateFormat = dateFormat.concat(format === "long" ? "JJJJ" : "JJ");
    else dateFormat = dateFormat.concat(format === "long" ? "YYYY" : "YY");

    return dateFormat;
};

export const convertDateRangeToUrlParamValue = (dateSelection: DatePickerSelection): string => {
    if (dateSelection instanceof Date) {
        return dayjs(dateSelection).format(URL_PARAM_DATE_FORMAT);
    }

    const from = dateSelection.from ? dayjs(dateSelection.from).format(URL_PARAM_DATE_FORMAT) : undefined;
    const to = dateSelection.to ? dayjs(dateSelection.to).format(URL_PARAM_DATE_FORMAT) : undefined;

    if (from && to) {
        return `${encodeURIComponent(from + BTW_CHAR + to).trim()}`;
    } else if (from) {
        return `${GTE_CHAR}${encodeURIComponent(from).trim()}`;
    } else if (to) {
        return `${LTE_CHAR}${encodeURIComponent(to).trim()}`;
    }

    return "";
};

export const convertUrlParamValueToDateRange = (value: string, format: string): string | DateRange => {
    if (!value) return "";

    if (value.includes(BTW_CHAR)) {
        const [from, to] = value.split("_").map((dateStr) => dateStr.trim() || undefined);
        return {
            from: from ? dayjs(from).format(format) : "",
            to: to ? dayjs(to).format(format) : "",
        };
    }

    if (value.startsWith(GTE_CHAR)) {
        return { from: dayjs(value.substring(1)).format(format), to: "" };
    }

    if (value.startsWith(LTE_CHAR)) {
        return { from: "", to: dayjs(value.substring(1)).format(format) };
    }

    return dayjs(value).format(format);
};

export const convertDateRangeUrlParamValueToString = (value: string, format: string): string => {
    if (!value) return "";

    if (value.includes(BTW_CHAR)) {
        const [from, to] = value.split("_").map((dateStr) => dateStr.trim() || undefined);
        return `${from ? dayjs(from).format(format) : ""} - ${to ? dayjs(to).format(format) : ""}`;
    }

    if (value.startsWith(GTE_CHAR)) {
        return `${GTE_CHAR}${dayjs(value.substring(1)).format(format)}`;
    }

    if (value.startsWith(LTE_CHAR)) {
        return `${LTE_CHAR}${dayjs(value.substring(1)).format(format)}`;
    }

    return dayjs(value).format(format);
};

export const convertToUTC = (dateString: string, format: string) => {
    return dayjs.utc(dateString, format, true).toDate();
};
