<template>
    <div class="facet-dropdown column">
        <div class="title qit-property-key ellipsis">{{ facet.title }}</div>
        <q-btn-dropdown
            no-caps
            unelevated
            no-route-dismiss
            color="white"
            @before-show="onOpenedHandler"
            class="full-width facet-dropdown-button"
            :class="{ 'date-time-facet': isDatetime }"
            align="between"
            menu-anchor="bottom left"
            menu-self="top left"
            v-model="dropDownVisible"
            :disable="disable"
            :data-qs="`facet-dropdown-${facet.id}`"
            dropdown-icon="fa-solid fa-caret-down"
            content-class="facet-dropdown-menu"
            :icon-right="isDatetime ? 'fa-regular fa-calendar-day icon-size-m' : undefined"
        >
            <template #label>
                <template v-if="isDatetime">
                    <DropdownTextField
                        v-if="selected?.length > 0"
                        :text="convertDateRangeUrlParamValueToString(selected[0].text, shortDateFormat)"
                        @on-clear="removeValue(selected[0].text)"
                    />
                    <span v-else class="q-ml-sm"> {{ getLocalizedDateFormat(locale, "short") }} </span>
                </template>
                <template v-else>
                    <DropdownMultiField
                        v-if="selected?.length > 0 && facet.displayOptions.isMultiSelect"
                        :selected="selected"
                        :on-remove="removeValue"
                        :max-tags="MAX_TAGS"
                        :disable="disable"
                    />
                    <DropdownSingleField
                        v-else-if="selected?.length > 0"
                        :selected="selected[0]"
                        :on-remove="removeValue"
                        :disable="disable"
                    />
                    <span v-else class="q-ml-sm">
                        {{ $t("core.All") }}
                    </span>
                </template>
            </template>
            <DropdownTemplate
                :loading="loading"
                :facet="facet"
                :aggregations="aggregations"
                :facet-values="filteredItems ?? []"
                :selected="selected"
                :on-select="valueChangedHandler"
                @on-modal-closed="hideDropdown()"
                @on-filter-changed="onFilterChanged"
                @on-open-date-picker="openDatePicker"
                @on-select-time-period="onSelectTimePeriod"
            />
        </q-btn-dropdown>
        <DatePickerModal
            v-if="isDatePickerOpen && selectedDateMode"
            :format="dateFormat"
            :hint="localizedDateFormat"
            :mode="selectedDateMode"
            :date="selectedDate"
            @on-close="closeDatePicker"
            @on-select-date="onSelectDate"
            @on-reset="onResetDate"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, toRefs, watchEffect } from "vue";
import { Facet, FacetSelected, FacetValue } from "../facets.model";
import DropdownMultiField from "./field/dropdown-multi-field.vue";
import DropdownSingleField from "./field/dropdown-single-field.vue";
import DropdownTemplate from "@/shared/facets/dropdown/content/dropdown-template.vue";
import { MIN_SEARCH_CHARS_FILTER, useFacetDropdownLazy } from "@/shared/facets/dropdown/facet-dropdown-lazy";
import DatePickerModal from "@/shared/components/date-picker/date-picker-modal.vue";
import {
    BTW_CHAR,
    convertDateRangeToUrlParamValue,
    convertDateRangeUrlParamValueToString,
    convertTimePeriod,
    convertUrlParamValueToDateRange,
    DateRange,
    getDateFormat,
    getLocalizedDateFormat,
    getShortDateFormat,
    TimePeriod,
    URL_PARAM_DATE_FORMAT,
    VALID_DATE_LENGTH,
} from "@/shared/utils/date-utils.ts";
import DropdownTextField from "@/shared/facets/dropdown/field/dropdown-text-field.vue";
import { getUiLanguage } from "@/shared/services/providers/language-provider.ts";
import { DateMode, DatePickerSelection } from "@/shared/components/date-picker/date-picker.types.ts";

export interface FacetDropdownProps {
    facet: Facet;
    selectedIds: string[];
    facetCollectionId: string;
    disable?: boolean;
    isLast?: boolean;
    fetchAggregation?: (facetID: string) => Promise<Record<string, number>>;
    modelValue?: boolean;
}

const props = defineProps<FacetDropdownProps>();

const emits = defineEmits<{
    (e: "onValueChanged", facetSelected: FacetSelected): void;
    (e: "onOpenFilter", facet: Facet): void;
    (e: "onLastElementMounted"): void;
    (e: "update:modelValue", value: boolean): void;
}>();

const MAX_TAGS = 5;

const { selectedIds, facet } = toRefs(props);
const loading = ref<boolean>(false);
const filterPhrase = ref<string>("");
const filteredItems = ref<FacetValue[]>([]);
const isDatePickerOpen = ref<boolean>(false);
const selectedDateMode = ref<DateMode | undefined>(undefined);

const { facetValues, selected, load, aggregations, refetchAggregation } = useFacetDropdownLazy(
    props.facetCollectionId,
    props.facet,
    selectedIds,
    props.fetchAggregation
);

const dropDownVisible = computed({
    get: () => props.modelValue ?? false,
    set: (value) => emits("update:modelValue", value),
});

const locale = computed(() => getUiLanguage());

const dateFormat = computed(() => {
    return getDateFormat(locale.value);
});

const localizedDateFormat = computed(() => {
    return getLocalizedDateFormat(locale.value);
});

const shortDateFormat = computed(() => {
    return getShortDateFormat(locale.value);
});

const selectedDate = computed<string | DateRange | undefined>(() => {
    if (props.facet.facetType !== "dateTime" || !selected.value || selected.value.length === 0) return undefined;

    return convertUrlParamValueToDateRange(selected.value[0].text, dateFormat.value);
});

onMounted(() => {
    if (props.isLast) {
        emits("onLastElementMounted");
    }
});

watchEffect(() => {
    filteredItems.value = facetValues.value ?? [];
});

watchEffect(() => {
    //load values first to show preselected values else load values on open dropdown
    if (props.facet.facetType !== "dateTime" && selectedIds.value?.length > 0 && facetValues.value === undefined)
        load();
});

const isDatetime = computed(() => {
    return facet.value.facetType === "dateTime";
});

const onOpenedHandler = async () => {
    emits("onOpenFilter", props.facet);

    //Datetime facets doesn't need to load values
    if (props.facet.facetType == "dateTime") {
        if (selected.value && selected.value[0]) {
            isDatePickerOpen.value = true;
            selectedDateMode.value = selected.value[0].text.length === VALID_DATE_LENGTH ? "date" : "dateRange";
            ///workaround to hide dropdown
            dropDownVisible.value = true;
            await nextTick();
            dropDownVisible.value = false;
            //----------------
        }
        return;
    }

    loading.value = true;

    //load values only once when opening dropdown
    await load();

    loading.value = false;
};

const valueChangedHandler = (selected: string[]) => {
    const facetsSelected: FacetSelected = {
        id: props.facet.id,
        referencedId: props.facet.referencedId,
        type: props.facet.facetType,
        values: selected,
    };

    emits("onValueChanged", facetsSelected);
};

const removeValue = (id: string) => {
    const newSelected = props.selectedIds.filter((val) => val !== id);

    const facetsSelected: FacetSelected = {
        id: props.facet.id,
        type: props.facet.facetType,
        referencedId: props.facet.referencedId,
        values: newSelected,
    };

    emits("onValueChanged", facetsSelected);
};

const hideDropdown = () => {
    dropDownVisible.value = false;
};

const onFilterChanged = (newFilterPhrase: string) => {
    if (newFilterPhrase.length >= MIN_SEARCH_CHARS_FILTER) {
        filterPhrase.value = newFilterPhrase;
    } else if (filterPhrase.value.length > newFilterPhrase.length) {
        filterPhrase.value = "";
    }

    if (facet.value.facetType === "keyword") {
        refetchAggregation(filterPhrase.value);
    } else {
        filteredItems.value =
            (filterPhrase.value.length >= MIN_SEARCH_CHARS_FILTER
                ? facetValues.value?.filter((x) => x.text.toLowerCase().includes(filterPhrase.value.toLowerCase()))
                : facetValues.value) ?? [];
    }
};

const openDatePicker = async (dateMode: DateMode) => {
    selectedDateMode.value = dateMode;
    isDatePickerOpen.value = true;
};

const closeDatePicker = () => {
    isDatePickerOpen.value = false;
};

const onSelectTimePeriod = (timePeriod: TimePeriod) => {
    let dateRange = convertTimePeriod(timePeriod, URL_PARAM_DATE_FORMAT);
    if (!dateRange) return;

    isDatePickerOpen.value = false;

    const values = [`${dateRange.from}${BTW_CHAR}${dateRange.to}`];
    const facetsSelected: FacetSelected = {
        id: props.facet.id,
        referencedId: props.facet.referencedId,
        type: props.facet.facetType,
        values,
    };

    emits("onValueChanged", facetsSelected);
};

const onSelectDate = (newSelectionDate: DatePickerSelection) => {
    let value = convertDateRangeToUrlParamValue(newSelectionDate);
    const facetsSelected: FacetSelected = {
        id: props.facet.id,
        referencedId: props.facet.referencedId,
        type: props.facet.facetType,
        values: [value],
    };
    emits("onValueChanged", facetsSelected);
    isDatePickerOpen.value = false;
};

const onResetDate = () => {
    isDatePickerOpen.value = false;
    if (selected.value && selected.value[0]) removeValue(selected.value[0].text);
};
</script>

<style lang="scss" scoped>
.facet-dropdown-button {
    min-height: 38px;
    padding: 0 $spacing-m 0 0;
}
.title {
    min-height: 17px;
}
.date-time-facet {
    :deep(.q-btn-dropdown__arrow) {
        display: none;
    }
}
</style>
<style lang="scss">
.facet-dropdown-menu {
    /*as soon as max-height is set, the q menu is displayed at the top there is no other way to recognize this state*/
    &[style*="max-height:"] {
        height: 100%;
    }
}
</style>
