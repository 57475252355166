import { computed, ComputedRef, ref, Ref, watchEffect } from "vue";
import { SearchContext } from "@/shared/search/search.types";
import { Facet, FacetSelected } from "@/shared/facets/facets.model";
import { SortEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import {
    ContentSort,
    InputMaybe,
    LanguageWithWildcard,
    MechanicEdge,
    MechanicFilter,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { generateOrGroupsByFacetsSelected } from "@/shared/facets/facets-filter-generator";
import { getSortEntries } from "@/shared/environment/filter/filter-types";
import { generateDynamicSearchMechanicArticlesOnlyAggregationsQuery } from "@/abilities/mechanic/helpers/article-search-helper";
import { createMechanicPartsListQuery } from "@/abilities/mechanic/graphql/mechanics-partslist.query";
import { useLazyQuery } from "@vue/apollo-composable";
import { loadMore } from "@/shared/components/scroll/lazy-loading-fetch";
import { getDataDisplayValue } from "@/shared/data-display-config/composable/data-display.helper";
import {
    QDataSource,
    QIT_PREVIEW_IMAGE_COLUMN,
    QIT_PREVIEW_IMAGE_ROW_FIELD,
} from "@/shared/components/table/q-data-source";
import { QTableProps } from "quasar";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { convertAggregationsResult } from "@/shared/aggregations/aggregations-resolver";
import { ADD_TO_COLLECTION_COL_NAME } from "@/abilities/mechanic/pages/article-search/articles-list-data-source.ts";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access.ts";
import { AccessFeature } from "@/shared/access-control/access-control.ts";

export const useMechanicArticlesDataSource = (
    searchContext: Ref<SearchContext>,
    selected: Ref<FacetSelected[]>,
    sort: Ref<SortEntry | undefined>,
    facet: Ref<Facet | undefined>
) => {
    const { hasAccess } = useHasAccess({ featureID: AccessFeature.spare_parts });
    const {
        result: dataDisplayResult,
        loading: dataConfigLoading,
        error: dataConfigError,
        priorityDataDisplayFields,
    } = useDataDisplayConfig(DataDisplayConfigId.parts);

    const generateMechanicArticleFilterVariables = (
        searchContext: SearchContext,
        selected: FacetSelected[],
        sort: SortEntry | undefined
    ) => {
        const mechanicFilter: MechanicFilter = {};

        //The wildcard search with * behaves like empty-string search, otherwise the regex will not fit and no results will be returned (see QIT-2531).
        if (searchContext.text && searchContext.text !== "*") {
            const titleLocale = "title".concat("_").concat(getDataQueryLanguages()[0]);
            const titleFallbackLocale = "title".concat("_").concat(getDataQueryLanguages()[1]);

            mechanicFilter.orGroup = [
                {
                    regex: {
                        articleNumber: `/${searchContext.text}.*/i`,
                    },
                    boost: 10,
                },
                {
                    regex: {
                        [titleLocale]: `/.*${searchContext.text}.*/i`,
                    },
                    boost: 10,
                },
                {
                    regex: {
                        [titleFallbackLocale]: `/.*${searchContext.text}.*/i`,
                    },
                    boost: 10,
                },
                {
                    fullText: {
                        query: searchContext.text,
                        fuzzy: true,
                        fields: {
                            [titleLocale]: 1,
                            [titleFallbackLocale]: 1,
                        },
                    },
                },
            ];
        }

        const copySelected = [...selected];
        const orGroups = generateOrGroupsByFacetsSelected(copySelected);

        if (orGroups.length > 0) {
            (mechanicFilter as any).andGroup = orGroups;
        }

        const sortEntries = getSortEntries(sort, getDataQueryLanguages());

        return {
            filter: mechanicFilter,
            acceptedLanguages: getDataQueryLanguages(),
            sortEntries,
            productId: searchContext.product,
            assetId: searchContext.asset,
        };
    };

    const filter = computed<
        | { filter: InputMaybe<MechanicFilter> }
        | {
              mechanicFilter: MechanicFilter;
              acceptedLanguages: LanguageWithWildcard[];
              sort: ContentSort;
          }
        | undefined
    >(() => {
        return generateMechanicArticleFilterVariables(searchContext.value, selected.value, sort.value);
    });

    const stop = watchEffect(() => {
        if (!dataDisplayResult.value || !sort.value) return;
        stop();

        load(
            createMechanicPartsListQuery({
                datafieldsFragment: dataDisplayResult.value.datafieldsFragment.datafields,
                teaserDatafieldsFragment: dataDisplayResult.value.datafieldsFragment.localizationDatafields,
                withPreviewImages: true,
            })
        );
    });

    const FIRST_LOAD = 100;
    const FETCH_MORE_LOAD = 50;

    const {
        loading: mechanicArticlesLoading,
        error: mechanicArticlesError,
        fetchMore,
        load,
        result: mechanicArticlesResult,
    } = useLazyQuery<any>(
        createMechanicPartsListQuery({}),
        computed(() => {
            return {
                ...filter.value,
                productId: searchContext.value.product,
                assetId: searchContext.value.asset,
                acceptedLanguages: getDataQueryLanguages(),
                first: FIRST_LOAD,
                sort: getSortEntries(sort.value, getDataQueryLanguages()),
            };
        })
    );

    const hasMoreResults = ref(true);

    function loadMoreArticles() {
        // do not load more articles if there are no more results
        if (!mechanicArticlesResult.value) {
            return;
        }
        // get the last article from the current result
        const lastArticle =
            mechanicArticlesResult.value.mechanics.mechanics[
                mechanicArticlesResult.value.mechanics.mechanics.length - 1
            ];
        // if there is no last article or no cursor, we cannot load more
        if (!lastArticle || !lastArticle.cursor) {
            return;
        }
        // load more articles
        loadMore(fetchMore, lastArticle.cursor, FETCH_MORE_LOAD).then((data) => {
            hasMoreResults.value = data?.data.mechanics.mechanics.length === FETCH_MORE_LOAD;
        });
    }

    const mechanicArticles = computed(() => {
        return mechanicArticlesResult.value?.mechanics?.mechanics ?? [];
    });

    const total = computed(() => {
        return mechanicArticlesResult.value?.mechanics?.total;
    });

    const loading = computed(() => {
        return mechanicArticlesLoading.value || dataConfigLoading.value;
    });

    const error = computed(() => {
        return dataConfigError.value || mechanicArticlesError.value;
    });

    const columns = computed(() => {
        if (!dataDisplayResult.value || dataDisplayResult.value.dataDisplayFields.length === 0) {
            //if no datadisplayfields configured, we need an empty column so that the rows are shown
            return [{ name: "", label: "", field: "", align: "left" }];
        }

        let result = dataDisplayResult.value?.dataDisplayFields.map((field) => {
            return {
                name: field.key,
                label: field.title,
                field: field.key,
                align: "left",
            };
        });

        if (hasAccess.value)
            result = [...result, { name: ADD_TO_COLLECTION_COL_NAME, label: "", field: "", align: "left" }];

        return [
            {
                name: QIT_PREVIEW_IMAGE_COLUMN,
                field: QIT_PREVIEW_IMAGE_ROW_FIELD,
                label: "",
            },
            ...result,
        ];
    });

    const mobileColumns = computed(() => {
        let columns = priorityDataDisplayFields.value.map((displayField) => {
            return {
                name: displayField.key,
                field: displayField.key,
                label: displayField.title,
                align: "left",
            };
        });

        if (hasAccess.value)
            columns = [...columns, { name: ADD_TO_COLLECTION_COL_NAME, label: "", field: "", align: "left" }];

        return columns;
    });

    const dataRows = computed(() => {
        return mechanicArticles.value?.map((edge: MechanicEdge) => {
            const node = edge?.node;
            if (!node) {
                return {};
            }
            const result: any = {};
            dataDisplayResult.value?.dataDisplayFields.forEach((dataDisplayField) => {
                const dataValue = getDataDisplayValue(dataDisplayField, node);
                result[dataDisplayField.key] = dataValue.formattedValue;
            });
            const dataDisplayFieldId = dataDisplayResult.value?.dataDisplayFields.find((field) => field.key === "id");
            if (!dataDisplayFieldId) {
                result["id"] = node.id;
            }
            result[QIT_PREVIEW_IMAGE_ROW_FIELD] = node.previewImage?.url;
            return result;
        });
    });

    const qDataSource: ComputedRef<QDataSource> = computed(() => {
        return {
            columns: columns.value as QTableProps["columns"],
            mobileColumns: mobileColumns.value as QTableProps["columns"],
            rows: dataRows.value,
        };
    });

    const resultListEmpty = computed(() => {
        return dataRows.value?.length === 0;
    });

    const fetchAggregation = async (searchPhrase: string) => {
        if (!facet.value) return {};

        const aggregationResult = await consumerClient.query({
            query: generateDynamicSearchMechanicArticlesOnlyAggregationsQuery(facet.value, searchPhrase),
            variables: filter.value,
        });

        const convertedAggregations = convertAggregationsResult(
            facet.value,
            aggregationResult.data["mechanics"].aggregations
        );

        return convertedAggregations[facet.value.id];
    };

    return {
        loading,
        error,
        qDataSource,
        resultListEmpty,
        hasMoreResults,
        loadMoreArticles,
        total,
        fetchAggregation,
        mechanicArticlesResult,
    };
};
