import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import { SearchParams } from "@/shared/search/search.types";
import { useLazyQuery } from "@vue/apollo-composable";
import { computed, watchEffect } from "vue";
import { generateSearchDocumentsQuery } from "../../graphql/documentation-search.query";
import { useSearchConfig } from "@/shared/search/search-config";
import { generateQueryFilterForSearch } from "@/shared/facets/facets-filter-generator";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access.ts";
import { AccessFeature } from "@/shared/access-control/access-control.ts";

export const useDocumentSearch = ({ selected, searchPhrase, sort, searchContext }: SearchParams) => {
    const COUNT = 20;

    //first fetch search configuration for document search
    const {
        result: documentSearchConfigResult,
        loading: documentSearchConfigLoading,
        getDataDisplayPropertiesByConfig,
    } = useSearchConfig(DataDisplayConfigId.documentSelection);
    const { hasAccess: fuzzy } = useHasAccess({ featureID: AccessFeature.fuzzy_search });

    const searchDocumentsQuery = computed(() => {
        if (!documentSearchConfigResult.value) return generateSearchDocumentsQuery({}, {}, {}, searchContext?.value);

        return generateSearchDocumentsQuery(
            documentSearchConfigResult.value.datafieldsFragment.datafields,
            documentSearchConfigResult.value.datafieldsFragment.localizationDatafields,
            documentSearchConfigResult.value.nodeFieldsDataFragment,
            searchContext?.value
        );
    });

    const searchDocumentsFilter = computed(() => {
        const { assetFilter, contentFilter, productId } = generateQueryFilterForSearch(
            selected.value,
            fuzzy.value,
            searchContext?.value,
            documentSearchConfigResult.value?.searchEntries ?? [],
            searchPhrase?.value
        );

        return {
            assetFilter,
            productId,
            contentFilter,
            acceptedLanguages: getDataQueryLanguages(),
            sort: sort?.value,
            after: "",
            first: COUNT,
        };
    });

    const {
        loading: searchLoading,
        error: searchError,
        result: searchResult,
        fetchMore,
        load,
    } = useLazyQuery(searchDocumentsQuery, searchDocumentsFilter);

    //load searchDocumentsQuery if search configuration resolved
    const stop = watchEffect(() => {
        if (!documentSearchConfigResult.value || !sort?.value) return;

        stop();
        load();
    });

    const loading = computed(() => searchLoading.value || documentSearchConfigLoading.value);

    const error = computed(() => {
        return searchError.value;
    });

    const result = computed(() => {
        if (searchContext?.value.asset && searchContext?.value.product) {
            return { contents: searchResult?.value?.assets?.assets[0]?.node.relatedContents };
        }
        if (searchContext?.value.product) {
            return { contents: searchResult?.value?.product?.relatedContents };
        }
        return searchResult.value;
    });

    const total = computed(() => {
        if (searchContext?.value.asset && searchContext?.value.product) {
            return { total: searchResult?.value?.assets[0]?.node.relatedContents.total };
        }
        if (searchContext?.value.product) {
            return { contents: searchResult?.value?.product?.relatedContents.total };
        }
        return searchResult.value.total;
    });

    const searchFields = computed(() => {
        return documentSearchConfigResult.value?.searchEntries ?? [];
    });

    return {
        result,
        total,
        searchFields,
        loading,
        error,
        fetchMore,
        getDataDisplayPropertiesByConfig,
    };
};
