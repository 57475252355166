import { computed, Ref, watchEffect } from "vue";
import { SearchContext } from "@/shared/search/search.types";
import { Facet, FacetSelected } from "@/shared/facets/facets.model";
import { SortEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { InputMaybe, ProductFilter } from "@/shared/services/graphql/generated/consumer-graph-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { generateOrGroupsByFacetsSelected } from "@/shared/facets/facets-filter-generator";
import { getSortEntries } from "@/shared/environment/filter/filter-types";
import { generateDynamicSearchProductsOnlyAggregationsQuery } from "@/abilities/product-and-asset/helpers/product-asset-search-helper";
import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import { createProductsQuery } from "@/abilities/product-and-asset/graphql/products.query";
import { useLazyQuery } from "@vue/apollo-composable";
import { ProductQueryResult } from "@/shared/services/graphql/generated/admin-graph-types";
import { createDataSource, createTiles } from "@/abilities/product-and-asset/pages/products-overview.model";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { convertAggregationsResult } from "@/shared/aggregations/aggregations-resolver";

export const useProductDataSource = (
    context: Ref<SearchContext>,
    emitSelectionInTiles: boolean,
    showButtons: boolean,
    selected: Ref<FacetSelected[]>,
    sort: Ref<SortEntry | undefined>,
    facet: Ref<Facet | undefined>
) => {
    function generateProductFilterVariables(
        searchContext: SearchContext,
        selected: FacetSelected[],
        sort: SortEntry | undefined
    ) {
        const productFilter: ProductFilter = {};

        //The wildcard search with * behaves like empty-string search, otherwise the regex will not fit and no results will be returned (see QIT-2531).
        if (searchContext.text && searchContext.text !== "*") {
            const titleLocale = "title".concat("_").concat(getDataQueryLanguages()[0]);
            const titleFallbackLocale = "title".concat("_").concat(getDataQueryLanguages()[1]);

            productFilter.orGroup = [
                {
                    regex: {
                        [titleLocale]: `/.*${searchContext.text}.*/i`,
                    },
                    boost: 10,
                },
                {
                    regex: {
                        [titleFallbackLocale]: `/.*${searchContext.text}.*/i`,
                    },
                    boost: 10,
                },
                {
                    fullText: {
                        query: searchContext.text,
                        fuzzy: true,
                        fields: {
                            [titleLocale]: 1,
                            [titleFallbackLocale]: 1,
                        },
                    },
                },
            ];
        }

        const copySelected = [...selected];
        const orGroups = generateOrGroupsByFacetsSelected(copySelected);

        if (orGroups.length > 0) {
            productFilter.andGroup = orGroups;
        }

        const sortEntries = getSortEntries(sort, getDataQueryLanguages());

        return {
            productFilter,
            acceptedLanguages: getDataQueryLanguages(),
            sortEntries,
        };
    }

    const productsQueryVariables = computed<{ filter: InputMaybe<ProductFilter> } | undefined>(() => {
        const productFilter = generateProductFilterVariables(context.value, selected.value, sort.value);
        return {
            filter: productFilter.productFilter as ProductFilter,
        };
    });

    const aggregationsFilter = computed<{ filter: InputMaybe<ProductFilter> } | undefined>(() => {
        //remove own facet from selected
        const selectedCopy = [...selected.value.filter((x) => x.referencedId !== facet.value?.referencedId)];
        const productFilter = generateProductFilterVariables(context.value, selectedCopy, sort.value);
        return {
            filter: productFilter.productFilter as ProductFilter,
        };
    });

    const {
        result: dataDisplayResult,
        loading: dataConfigLoading,
        error: dataError,
    } = useDataDisplayConfig(DataDisplayConfigId.productSelection);

    const stop = watchEffect(() => {
        if (!dataDisplayResult.value || !sort.value) return;

        stop();
        load(
            createProductsQuery(
                dataDisplayResult.value.datafieldsFragment.datafields,
                dataDisplayResult.value.datafieldsFragment.localizationDatafields
            )
        );
    });

    const {
        loading: productsLoading,
        error: productsError,
        result,
        fetchMore,
        load,
    } = useLazyQuery(
        createProductsQuery({}, {}),
        computed(() => {
            return {
                ...productsQueryVariables.value,
                first: 100,
                acceptedLanguages: getDataQueryLanguages(),
                sort: getSortEntries(sort.value, getDataQueryLanguages()),
            };
        })
    );

    const loading = computed(() => {
        return productsLoading.value || dataConfigLoading.value || !sort.value;
    });

    const error = computed(() => {
        return dataError.value || productsError.value;
    });

    const products = computed(() => {
        return result.value?.products;
    });

    const total = computed(() => {
        return result.value?.products.total;
    });

    const dataSource = createDataSource(emitSelectionInTiles, showButtons);

    const tiles = computed(() => {
        return createTiles(dataSource, products.value ? (products.value as ProductQueryResult).products : []);
    });

    const fetchAggregation = async (searchPhrase: string) => {
        if (!facet.value) return {};

        const aggregationResult = await consumerClient.query({
            query: generateDynamicSearchProductsOnlyAggregationsQuery(facet.value, searchPhrase),
            variables: aggregationsFilter.value,
        });

        const convertedAggregations = convertAggregationsResult(
            facet.value,
            aggregationResult.data["products"].aggregations
        );

        return convertedAggregations[facet.value.id];
    };

    return {
        loading,
        error,
        tiles,
        products,
        fetchMore,
        total,
        fetchAggregation,
    };
};
