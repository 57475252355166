<template>
    <Dialog :visible="visible" :title="$t('core.Reset connection')" @on-close="emits('onClose')">
        <template #content>
            <div class="q-mb-md">
                {{
                    $t(
                        "core.There is already an active connection A new connection is created when resetting Previously connected devices are permanently disconnected"
                    )
                }}
            </div>
            <q-separator class="q-mb-md" />
            <div class="row flex-end">
                <qit-button color="secondary" class="q-mr-sm" :label="$t('core.Cancel')" @click="emits('onClose')" />
                <qit-button color="destructive" :label="$t('core.Reset connection')" @click="emits('onResetLink')" />
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import Dialog from "@/shared/components/dialog/dialog.vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";

defineProps<{ visible: boolean }>();

const emits = defineEmits<{ onClose: []; onResetLink: [] }>();
</script>
