<template>
    <q-list data-qs="date-range-list">
        <q-item clickable v-close-popup @click="() => openDatePicker('date')" data-qs="select-date-btn">
            <q-item-section>{{ $t("core.Date") }} </q-item-section>
            <q-item-section avatar>
                <q-icon name="fa-regular fa-calendar-day default-grey-icon-color" size="xs" />
            </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="() => openDatePicker('dateRange')" data-qs="select-time-period-btn">
            <q-item-section> {{ $t("core.Time period") }}</q-item-section>
            <q-item-section avatar>
                <q-icon name="fa-regular fa-arrows-left-right default-grey-icon-color" size="xs" />
            </q-item-section>
        </q-item>
        <q-separator />
        <q-item clickable v-close-popup @click="selectTimePeriod('last30Days')" data-qs="select-last-30-days-btn">
            {{ $t("core.Last 30 days") }}
        </q-item>
        <q-item clickable v-close-popup @click="selectTimePeriod('currentMonth')" data-qs="select-current-month-btn">
            {{ $t("core.Current month") }}
        </q-item>
        <q-item
            clickable
            v-close-popup
            @click="selectTimePeriod('lastMonth')"
            class="items-center"
            data-qs="select-last-month-btn"
        >
            {{ $t("core.Last month") }}
        </q-item>
        <q-item
            clickable
            v-close-popup
            @click="selectTimePeriod('currentYear')"
            class="items-center"
            data-qs="select-current-year-btn"
        >
            {{ $t("core.Current year") }}
        </q-item>
    </q-list>
</template>

<script setup lang="ts">
import { TimePeriod } from "@/shared/utils/date-utils.ts";
import { DateMode } from "@/shared/components/date-picker/date-picker.types.ts";

const emits = defineEmits<{
    onOpenDatePicker: [dateMode: DateMode];
    onSelectTimePeriod: [timePeriod: TimePeriod];
}>();

const openDatePicker = (dateMode: DateMode) => {
    emits("onOpenDatePicker", dateMode);
};

const selectTimePeriod = (timePeriod: TimePeriod) => {
    emits("onSelectTimePeriod", timePeriod);
};
</script>

<style lang="scss" scoped>
.items-center {
    padding: 0 16px;
}
</style>
