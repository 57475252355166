import { SearchContext, SearchField } from "@/shared/search/search.types";
import { FacetSelected } from "@/shared/facets/facets.model";
import {
    AssetFilter,
    ContentFilter,
    DateRange,
    FieldDefinitionTypes,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { generateSearchFieldsFilterClause } from "@/shared/utils/search/search-fields-filter-clause";
import {
    BTW_CHAR,
    convertToUTC,
    dayjs,
    GTE_CHAR,
    LTE_CHAR,
    URL_PARAM_DATE_FORMAT,
    VALID_DATE_LENGTH,
} from "@/shared/utils/date-utils.ts";

type QueryFilter =
    | { taxonomy?: Record<string, string>; equals?: Record<string, string> }
    | { andGroup: { range: Record<string, string> }[] };

export type OrGroupQuery = { orGroup: QueryFilter[] }[];

export const generateOrGroupsByFacetsSelected = (selected: FacetSelected[]): OrGroupQuery => {
    const generateOrQueryParts = (val: FacetSelected) => {
        return val.values.map((value) => {
            const filters: Record<string, string> = {};
            filters[val.referencedId] = value;
            if (val.type === FieldDefinitionTypes.taxonomy)
                return {
                    taxonomy: filters,
                };

            return {
                equals: filters,
            };
        });
    };

    const generateFilterPartsForDateFacets = (val: FacetSelected) => {
        return val.values.flatMap((value): any => {
            const dateRange = parseDateRange(value);

            if (!dateRange) return undefined;

            return {
                andGroup: dateRange.map((date) => {
                    return { range: { [val.referencedId]: date } };
                }),
            };
        });
    };

    const selectedWithValues = selected.length > 0 ? selected.filter((x) => x.values.length > 0) : [];

    return selectedWithValues.map((x) => ({
        orGroup:
            x.type === FieldDefinitionTypes.dateTime ? generateFilterPartsForDateFacets(x) : generateOrQueryParts(x),
    }));
};

export const generateQueryFilterForSearch = (
    selected: FacetSelected[],
    fuzzy: boolean,
    searchContext?: SearchContext,
    searchFields?: SearchField[],
    searchPhrase?: string
): { contentFilter: ContentFilter; assetFilter: AssetFilter | undefined; productId: string | undefined } => {
    const copySelected = [...selected];
    const contentFilter: ContentFilter = {};
    let assetFilter: AssetFilter | undefined;
    let productId: string | undefined;

    if (searchContext?.product && searchContext?.asset) {
        assetFilter = { equals: { productId: searchContext.product, assetId: searchContext.asset } };
    } else if (searchContext?.product) {
        productId = searchContext.product;
    }

    if (searchPhrase) {
        //get filter clause for search fields and add to content filter
        const searchFilterClause = generateSearchFieldsFilterClause(searchPhrase, searchFields ?? [], fuzzy);
        contentFilter.orGroup = searchFilterClause.orGroup;
    }

    //get filter clause for selected facets and add to content filter
    const orGroups = generateOrGroupsByFacetsSelected(copySelected);
    if (orGroups.length > 0) contentFilter.andGroup = orGroups;

    return { contentFilter, assetFilter, productId };
};

export const parseDateRange = (param: string | null): DateRange[] | null => {
    if (!param) return null;

    if (param.startsWith(GTE_CHAR)) {
        return [
            {
                gte: {
                    date: dayjs(convertToUTC(param.substring(1), URL_PARAM_DATE_FORMAT))
                        .startOf("day")
                        .toISOString(),
                },
            },
        ];
    }
    if (param.startsWith(LTE_CHAR)) {
        return [
            {
                lte: {
                    date: dayjs(convertToUTC(param.substring(1), URL_PARAM_DATE_FORMAT))
                        .endOf("day")
                        .toISOString(),
                },
            },
        ];
    }
    if (param.includes(BTW_CHAR)) {
        const [from, to] = param.split(BTW_CHAR).map((dateStr) => dateStr.trim());

        return [
            { gte: { date: dayjs(convertToUTC(from, URL_PARAM_DATE_FORMAT)).startOf("day").toISOString() } },
            { lte: { date: dayjs(convertToUTC(to, URL_PARAM_DATE_FORMAT)).endOf("day").toISOString() } },
        ];
    } else if (param.length === VALID_DATE_LENGTH) {
        return [
            { gte: { date: dayjs(convertToUTC(param, URL_PARAM_DATE_FORMAT)).startOf("day").toISOString() } },
            { lte: { date: dayjs(convertToUTC(param, URL_PARAM_DATE_FORMAT)).endOf("day").toISOString() } },
        ];
    }

    return null;
};
