export enum AccessAction {
    access = "access",
    query = "query",
    write = "write",
    delete = "delete",
    administrate = "administrate",
}

/**
 * Gesamtes Feature, hat Tenant oder nicht
 */
export enum AccessFeature {
    api = "api",
    notes = "notes",
    collections = "collections",
    documentation_menu = "documentation_menu",
    activities_menu = "activities_menu",
    spare_parts = "spare_parts",
    fdi_shop_connector = "fdi_shop_connector",
    playground = "playground",
    products = "products",
    activities = "activities",
    dashboard = "dashboard",
    fuzzy_search = "fuzzy_search",
    file_manager = "file_manager",
    //Temporary hoops hover feature flag
    hoops_hover = "hoops_hover",
    app = "app",
    rag_gui = "rag_gui",
    public_notes = "public_notes",
    topic_navigation = "topic_navigation",
    rag_feedback = "rag_feedback",
}

/**
 * User-spezifische Berechtigungen innerhalb Features
 */
export enum AccessResource {
    admin_area = "admin-area",
    contents = "contents",
    activities = "activities",
    datas = "datas",
    collections = "collections",
    contents_ingest_ms_help_2 = "contents.ingest-ms-help-2",
    contents_ingest_arbitrary_file = "contents.ingest-arbitrary-file",
    data_display_configs = "data-display-configs",
    facet_collections = "facet-collections",
    notifications = "notifications",
    metadata_definitions = "metadata-definitions",
    metadata_definitions_ingest_metadata_model = "metadata-definitions.ingest-metadata-model",
    static_pages = "static-pages",
    static_pages_menu = "static-pages.menu",
    notes = "notes",
    notes_comments = "notes.comments",
    theme = "themes",
    users = "users",
    access_filters = "access-filters",
    tenant = "tenant",
    webhook_configs = "webhook-configs",
    jobs = "jobs",
    hit_list_configs = "hit-list-configs",
    search_configs = "search-configs",
    users_api_keys = "users.api-keys",
    user_app_key = "user.app-key",
    data_ingest_data = "data.ingest-data",
    files = "files",
    products = "products",
    mechanics = "mechanics",
    userSettings = "user-settings",
    assets = "assets",
    config_3d = "config.3d",
    config_product_and_asset = "config.product-and-asset",
    config_shop = "config.shop",
    shop = "shop",
}
