import { onMounted } from "vue";

interface heap {
    addUserProperties: (value: Record<string, string>) => void;
    track: (event: string, options?: Record<string, string>) => void;
}

export const useHeapIO = () => {
    let heapIO: heap | undefined;
    onMounted(() => {
        if ("heap" in window && window.heap) {
            heapIO = window.heap as heap;
        }
    });

    const trackEvent = (event: string, options?: Record<string, string>) => {
        heapIO?.track(event, options);
    };

    const addUserProperty = (value: Record<string, string>) => {
        heapIO?.addUserProperties(value);
    };

    const addAuthenticatedProperty = () => {
        addUserProperty({ Authenticated: "true" });
    };

    const trackAssistantCompletion = () => {
        trackEvent("ai_assistant_completion");
    };

    return { trackAssistantCompletion, addAuthenticatedProperty };
};
