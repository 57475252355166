import { computed, ref, Ref } from "vue";
import { SearchContext } from "@/shared/search/search.types";
import { Facet, FacetSelected } from "@/shared/facets/facets.model";
import { SortEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { AssetFilter } from "@/shared/services/graphql/generated/consumer-graph-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { generateOrGroupsByFacetsSelected } from "@/shared/facets/facets-filter-generator";
import { getSortEntries } from "@/shared/environment/filter/filter-types";
import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import { createAssetsQuery } from "@/abilities/product-and-asset/graphql/assets.query";
import { useQuery } from "@vue/apollo-composable";
import { AssetQueryResult } from "@/shared/services/graphql/generated/admin-graph-types";
import { createDataSource, createListCards } from "@/abilities/product-and-asset/pages/assets-overview.model";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { convertAggregationsResult } from "@/shared/aggregations/aggregations-resolver";
import { generateDynamicSearchAssetsOnlyAggregationsQuery } from "@/abilities/product-and-asset/helpers/product-asset-search-helper";

export const useAssetDataSource = (
    searchContext: Ref<SearchContext>,
    showJumpToProduct: boolean,
    emitSelectionInTiles: boolean,
    selected: Ref<FacetSelected[]>,
    sort: Ref<SortEntry | undefined>,
    facet: Ref<Facet | undefined>
) => {
    const isLoading = ref<boolean>(false);

    function generateAssetFilterVariables(
        searchContext: SearchContext,
        selected: FacetSelected[],
        sort: SortEntry | undefined
    ) {
        const assetFilter: AssetFilter = {};

        //The wildcard search with * behaves like empty-string search, otherwise the regex will not fit and no results will be returned (see QIT-2531).
        if (searchContext.text && searchContext.text !== "*") {
            const titleLocale = "title".concat("_").concat(getDataQueryLanguages()[0]);
            const titleFallbackLocale = "title".concat("_").concat(getDataQueryLanguages()[1]);

            assetFilter.orGroup = [
                {
                    regex: {
                        assetId: `/${searchContext.text}.*/i`,
                    },
                    boost: 10,
                },
                {
                    regex: {
                        [titleLocale]: `/.*${searchContext.text}.*/i`,
                    },
                    boost: 10,
                },
                {
                    regex: {
                        [titleFallbackLocale]: `/.*${searchContext.text}.*/i`,
                    },
                    boost: 10,
                },
                {
                    fullText: {
                        query: searchContext.text,
                        fuzzy: true,
                        fields: {
                            [titleLocale]: 1,
                            [titleFallbackLocale]: 1,
                        },
                    },
                },
            ];
        }

        const copySelected = [...selected];
        const orGroups = generateOrGroupsByFacetsSelected(copySelected);

        if (orGroups.length > 0) {
            assetFilter.andGroup = orGroups;
        }

        assetFilter.equals = searchContext.product ? { productId: searchContext.product } : undefined;

        const sortEntries = getSortEntries(sort, getDataQueryLanguages());

        return {
            filter: assetFilter,
            acceptedLanguages: getDataQueryLanguages(),
            sort: sortEntries,
        };
    }

    const query = computed(() => {
        if (dataDisplayResult.value)
            return createAssetsQuery(
                dataDisplayResult.value.datafieldsFragment.datafields,
                dataDisplayResult.value.datafieldsFragment.localizationDatafields
            );

        return createAssetsQuery({}, {});
    });

    const filter = computed(() => {
        //remove own facet from selected
        const selectedCopy = [...selected.value.filter((x) => x.referencedId !== facet.value?.referencedId)];

        return generateAssetFilterVariables(searchContext.value, selectedCopy, sort.value);
    });

    const {
        result: dataDisplayResult,
        loading: dataConfigLoading,
        error: dataError,
    } = useDataDisplayConfig(DataDisplayConfigId.assetSelection);

    const {
        error: assetsError,
        result,
        fetchMore,
        refetch: refetchQuery,
    } = useQuery(
        query,
        filter,
        computed(() => ({
            enabled: !!(dataDisplayResult.value && sort.value),
        }))
    );

    const loading = computed(() => {
        return isLoading.value || dataConfigLoading.value;
    });

    const error = computed(() => {
        return dataError.value || assetsError.value;
    });

    const assets = computed(() => {
        return result.value?.assets;
    });

    const dataSource = createDataSource(showJumpToProduct, emitSelectionInTiles);

    const listCards = computed(() => {
        return createListCards(dataSource, assets.value ? (assets.value as AssetQueryResult).assets : []);
    });

    const refetch = async () => {
        isLoading.value = true;

        const assetFilter = generateAssetFilterVariables(searchContext.value, selected.value, sort.value);
        await refetchQuery(assetFilter);

        isLoading.value = false;
    };

    const fetchAggregation = async (searchPhrase: string) => {
        if (!facet.value) return {};

        const aggregationResult = await consumerClient.query({
            query: generateDynamicSearchAssetsOnlyAggregationsQuery(facet.value, searchPhrase),
            variables: filter.value,
        });

        const convertedAggregations = convertAggregationsResult(
            facet.value,
            aggregationResult.data.assets.aggregations
        );

        return convertedAggregations[facet.value.id];
    };

    return {
        refetch,
        loading,
        error,
        listCards,
        assets,
        fetchMore,
        fetchAggregation,
    };
};
