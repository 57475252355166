import {
    Ability,
    DataProcessType,
    NavigationMenuEntry,
    QualificationLevelSelectList,
    Route,
    RouteElementWithParam,
    SearchTab,
    Widget,
} from "@/shared/environment/ability.types";
import {
    LocationQuery,
    RouteLocationNormalizedGeneric,
    RouteLocationNormalizedLoadedGeneric,
    NavigationGuardNext,
} from "vue-router";
import { createRouteFromRouteElements } from "@/shared/environment/ability-route-helper";
import { CommonRouteAliases, CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import { ProcessType } from "@/shared/services/graphql/generated/consumer-graph-types";
import ProductTitleSpan from "../pages/components/breadcrumb/product-title-span.vue";
import AssetTitleSpan from "../pages/components/breadcrumb/asset-title-span.vue";
import ProductImportWizard from "../pages/components/data-process/product/product-import-wizard.vue";
import AssetOptionImportWizard from "../pages/components/data-process/asset-option/asset-option-import-wizard.vue";
import AssetImportWizard from "../pages/components/data-process/asset/asset-import-wizard.vue";
import ProductSearch from "../pages/product-search.vue";
import AssetSearch from "../pages/asset-search.vue";
import ProductSelectionForQualification from "../pages/product-list-for-qualification.vue";
import AssetSelectionForQualification from "../pages/asset-list-for-qualification.vue";
import LastProducts from "../pages/widget/last-products.widget.vue";
import LastAssets from "../pages/widget/last-assets.widget.vue";
import { AccessFeature } from "@/shared/access-control/access-control";
import { useMoreMenuProductItems } from "../actions/product-actions";
import { computedText } from "@/shared/i18n/translation-types";
import { CURRENT_TAB_PARAM_NAME, TAB_ASSETS_ALIAS } from "../helpers/product-selection-routing";
import { useAssetNames } from "@/abilities/product-and-asset/composables/asset-names";
import { useProductAndAssetAndText } from "@/abilities/product-and-asset/composables/product-and-asset-and-text";
import { useProductNames } from "@/abilities/product-and-asset/composables/product-names";
import { useProductAndAssetSettingsAsync } from "@/shared/configurations/product-and-asset/product-and-asset-settings-async";

export const abilityAlias = "product-and-asset";

export class ProductAndAssetAbility implements Ability {
    alias = abilityAlias;
    access = {
        accessFeature: AccessFeature.products,
    };

    getMenuEntries = useMoreMenuProductItems;

    getWidgets = (): Array<Widget> => [
        {
            widgetContextAlias: ["dashboard"],
            widgetComponent: LastProducts,
        },
        {
            widgetContextAlias: ["dashboard"],
            widgetComponent: LastAssets,
        },
    ];

    getNavigationMenuEntries = (): Array<NavigationMenuEntry> => [
        {
            title: computedText(useProductAndAssetAndText()),
            icon: "fa-regular fa-grid-2",
            internalRoute: {
                alias: CommonRouteAliases.productSelection,
            },
        },
    ];

    getRoutes = (): Array<Route> => [
        {
            alias: CommonRouteAliases.product,
            params: [CommonRouteAliases.product],
            getSearchParams: (currentElement: RouteElementWithParam) => {
                const result: Record<string, string> = {};

                if (currentElement.namedParams[currentElement.alias])
                    result[CommonRouteAliases.product] = currentElement.namedParams[currentElement.alias];

                return { routeParams: result, searchTab: CommonSearchTabAliases.commonArticles };
            },
            getComponent: () => import("@/abilities/product-and-asset/pages/product-detail-page.vue"),
            isRoot: true,
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return [
                    {
                        content: {
                            componentDefinition: {
                                getComponent: () => ProductTitleSpan,
                                props: {
                                    product: currentElement.namedParams[currentElement.alias],
                                },
                            },
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
        },
        {
            alias: CommonRouteAliases.asset,
            params: [CommonRouteAliases.asset],
            getSearchParams: (currentElement: RouteElementWithParam) => {
                const result: Record<string, string> = {};

                if (currentElement.namedParams[currentElement.alias])
                    result[CommonRouteAliases.asset] = currentElement.namedParams[currentElement.alias];

                return { routeParams: result, searchTab: CommonSearchTabAliases.commonArticles };
            },
            getComponent: () => import("@/abilities/product-and-asset/pages/asset-detail-page.vue"),
            parentAliases: [CommonRouteAliases.product],
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return [
                    {
                        content: {
                            componentDefinition: {
                                getComponent: () => AssetTitleSpan,
                                props: {
                                    asset: currentElement.namedParams[currentElement.alias],
                                },
                            },
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
            beforeEnter: async (
                to: RouteLocationNormalizedGeneric,
                _: RouteLocationNormalizedLoadedGeneric,
                next: NavigationGuardNext
            ) => {
                const { assetsActivated } = await useProductAndAssetSettingsAsync();
                if (!assetsActivated) {
                    const params = to.params;
                    let path = to.path;
                    if (params.asset) {
                        path = to.path.replace(CommonRouteAliases.asset + "/" + params.asset, "").replace("//", "/");
                    }
                    next({ path: path });
                } else {
                    next();
                }
            },
        },
        {
            alias: CommonRouteAliases.productSelection,
            params: [],
            isRoot: true,
            getComponent: () => import("@/abilities/product-and-asset/pages/product-selection-main.vue"),
            getBreadCrumbs: (
                parentElements: Array<RouteElementWithParam>,
                currentElement: RouteElementWithParam,
                translateMethod: (text: string) => string
            ) => {
                return [
                    {
                        content: {
                            simpleText: translateMethod("product-and-asset.Products & Assets"),
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
            getSearchParams: (_currentElement: RouteElementWithParam, routeQuery: LocationQuery) => {
                //if product-selection and tab is products -> CommonSearchTabAliases.products
                const searchTab = (routeQuery?.[CURRENT_TAB_PARAM_NAME] as string) ?? CommonSearchTabAliases.products;
                return { searchTab };
            },
            beforeEnter: async (
                to: RouteLocationNormalizedGeneric,
                _: RouteLocationNormalizedLoadedGeneric,
                next: NavigationGuardNext
            ) => {
                const { assetsActivated } = await useProductAndAssetSettingsAsync();
                if (!assetsActivated && to.query[CURRENT_TAB_PARAM_NAME] === TAB_ASSETS_ALIAS) {
                    next({ path: to.path, query: { ...to.query, [CURRENT_TAB_PARAM_NAME]: undefined } });
                } else {
                    next();
                }
            },
        },
    ];

    getDataProcessTypes = (): Array<DataProcessType> => [
        {
            type: ProcessType.product,
            dialogFormComponent: {
                getComponent: () => ProductImportWizard,
                props: {
                    title: "product-and-asset.Create New Process (Product Information)",
                },
            },
            tileSettings: {
                title: "product-and-asset.Product information",
                text: "product-and-asset.Configure an import for meta information about your products",
                icon: "fa-solid fa-box product-color",
                cssClass: "product-process-tile",
            },
        },
        {
            type: ProcessType.asset,
            tileSettings: {
                title: "product-and-asset.Asset information",
                text: "product-and-asset.Configure an import for meta information about your assets",
                icon: "fa-solid fa-tag asset-color",
                cssClass: "asset-process-tile",
            },
            dialogFormComponent: {
                getComponent: () => AssetImportWizard,
                props: {
                    title: "product-and-asset.Create New Process (Asset Information)",
                },
                emits: {
                    onFormValidation: null,
                },
            },
        },
        {
            type: ProcessType.assetOption,
            tileSettings: {
                title: "product-and-asset.Asset options",
                text: "product-and-asset.Configure an import for options to your assets",
                icon: "fa-solid fa-tags asset-color",
                cssClass: "asset-process-tile",
            },
            dialogFormComponent: {
                getComponent: () => AssetOptionImportWizard,
                props: {
                    title: "product-and-asset.Create New Process (Asset Options)",
                },
            },
        },
    ];

    getSearchTabs: () => Array<SearchTab> = () => [
        {
            alias: CommonSearchTabAliases.products,
            title: computedText(useProductNames("plural")),
            searchComponent: {
                getComponent: () => ProductSearch,
            },
        },
        {
            alias: CommonSearchTabAliases.assets,
            title: computedText(useAssetNames("plural")),
            searchComponent: {
                getComponent: () => AssetSearch,
            },
        },
    ];

    getQualificationSelectList: () => Array<QualificationLevelSelectList> = () => [
        {
            alias: CommonRouteAliases.product,
            title: computedText(useProductNames("plural")),
            component: {
                getComponent: () => ProductSelectionForQualification,
            },
        },
        {
            alias: CommonRouteAliases.asset,
            title: computedText(useAssetNames("plural")),
            component: {
                getComponent: () => AssetSelectionForQualification,
            },
        },
    ];
}
