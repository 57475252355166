import {
    FacetDefinitionsDocument,
    FieldDefinitionTypes,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { Facet, FacetValue } from "../facets.model";
import { computed, ref, Ref } from "vue";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";

export const MIN_SEARCH_CHARS_FILTER = 3;

export const useFacetDropdownLazy = (
    facetCollectionId: string,
    facet: Facet,
    selectedIds: Ref<string[]>,
    fetchAggregation?: (searchPhrase: string) => Promise<Record<string, number>>
) => {
    const loading = ref<boolean>(false);
    const aggregations = ref<Record<string, number>>();
    const facetValues = ref<FacetValue[]>();
    const facetDefinitionsResult = ref();

    const convertFacetValuesResult = (result: any) => {
        let values: FacetValue[] = [];
        const facetDefinition = result.facetsCollection?.facets?.[0]?.definition as unknown as any;

        if (!facetDefinition) return values;

        //only return values with existing aggregations for keywords facets
        if (facetDefinition.__typename === "KeywordFacetDefinition" && aggregations.value) {
            for (const [key] of Object.entries(aggregations.value)) {
                values.push({ id: key, text: key });
            }
            return values;
        }

        if (facetDefinition.values?.length === 0) return values;

        if (
            facetDefinition.__typename === "TaxonomyFacetDefinition" ||
            facetDefinition.__typename === "EnumFacetDefinition"
        ) {
            values =
                facetDefinition.values.map((x: any) => {
                    if (!x.pathKeys)
                        return {
                            id: x.key as string,
                            text: (x.teasers?.title || "") as string,
                        };

                    return {
                        id: x.key as string,
                        text: (x.teasers?.title || "") as string,
                        pathKeys: x.pathKeys,
                    };
                }) || undefined;

            //show only values with hits except preselected values e.g. deeplink
            values =
                values?.filter((x) => {
                    if (selectedIds.value.includes(x.id)) return true;

                    if (aggregations.value?.[x.id]) return aggregations.value?.[x.id] > 0;

                    return false;
                }) || [];

            //approve unknown
            if (aggregations.value)
                for (const [key] of Object.entries(aggregations.value)) {
                    if (
                        values.find((x) => {
                            return x.id === key;
                        })
                    )
                        continue;
                    values.push({ id: key, text: key, approveUnknownValue: true });
                }
        }

        return values;
    };

    const selected = computed(() => {
        const selectedArray: FacetValue[] = [];

        selectedIds.value.forEach((x) => {
            if (facet.facetType === FieldDefinitionTypes.dateTime) {
                selectedArray.push({ id: facet.referencedId, text: x });
                return;
            }

            const facetResult = facetDefinitionsResult.value?.facetsCollection?.facets?.[0] as unknown as any;
            const facetDefinition = facetResult?.definition as unknown as any;
            if (!facetDefinition) return;

            if (
                facetDefinition.__typename === "TaxonomyFacetDefinition" ||
                facetDefinition.__typename === "EnumFacetDefinition"
            ) {
                const found = facetDefinition.values
                    ?.map((y: any) => {
                        return { key: y.key, teasers: y.teasers, pathKeys: y.pathKeys };
                    })
                    .find((val: { key: string }) => val.key === x);
                if (found)
                    selectedArray.push({ id: found.key, text: found.teasers?.title ?? "", pathKeys: found.pathKeys });
                else {
                    //approve unknown
                    selectedArray.push({ id: x, text: x, approveUnknownValue: true });
                }
            } else if (facetDefinition.__typename === "KeywordFacetDefinition") {
                selectedArray.push({ id: x, text: x });
            } else if (facetDefinition.__typename === "DateTimeFacetDefinition") {
                selectedArray.push({ id: facet.referencedId, text: x });
            }
        });

        return selectedArray;
    });

    async function loadAggregations(searchPhrase?: string) {
        if (!fetchAggregation) return;

        try {
            aggregations.value = await fetchAggregation(searchPhrase ?? "");
        } catch (e) {
            console.error(e);
        }
    }

    const loadFacetValues = async () => {
        if (!facetDefinitionsResult.value) {
            const facetValuesResult = await consumerClient.query({
                query: FacetDefinitionsDocument,
                variables: {
                    id: facetCollectionId,
                    referencedIds: [facet.id],
                    acceptedLanguages: getDataQueryLanguages(),
                },
            });

            if (!facetValuesResult.data) return;

            facetDefinitionsResult.value = facetValuesResult.data;
        }

        facetValues.value = convertFacetValuesResult(facetDefinitionsResult.value);
    };

    const load = async () => {
        loading.value = true;

        await loadAggregations();
        await loadFacetValues();

        loading.value = false;
    };

    const refetchAggregation = async (searchPhase: string) => {
        loading.value = true;

        await loadAggregations(searchPhase);
        facetValues.value = convertFacetValuesResult(facetDefinitionsResult.value);

        loading.value = false;
    };

    return {
        loading,
        load,
        facetValues,
        selected,
        refetchAggregation,
        aggregations,
    };
};
